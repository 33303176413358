<script>
import NavBar from "@/components/nav-bar";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";
import router from "@/router";

export default {
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    router() {
      return router
    },
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
  components: {
    NavBar,
    RightBar,
    Footer
  }
};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar />
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="17" />
            </span>
          </router-link>
          <button type="button" class="
              btn btn-sm
              p-0
              fs-20
              header-item
              float-end
              btn-vertical-sm-hover
            " id="vertical-hover">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <b-container fluid>
            <ul class="navbar-nav h-100" id="navbar-nav">
              <li class="menu-title">
                <span data-key="t-menu"> {{ $t("t-menu") }}</span>
              </li>

              <li class="nav-item">
                <b-link class="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarDashboards">
                  <i class="bx bxs-dashboard"></i>
                  <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
                </b-link>

                <div class="collapse menu-dropdown" id="sidebarDashboards">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/dashboard/messages" class="nav-link custom-abc text-dark" data-key="Mensajes">
                        {{ $t("Mensajes") }}
                      </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/dashboard/apps" class="nav-link text-dark" data-key="t-apps">
                        {{ $t("t-apps") }}
                      </router-link>
                    </li>
                  </ul>
                </div>

              </li>

              <li class="nav-item">
                <router-link
                    to="/groups"
                    class="nav-link menu-link"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarGroups"
                    :class="{ 'active': router.name === 'groups' }"
                >
                  <i class="lab las la-users"></i>
                  <span data-key="Grupos">{{ $t("Grupos") }}</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                    to="/contacts"
                    class="nav-link menu-link"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarContacts"
                    :class="{ 'active': router.name === 'contacts' }"
                >
                  <i class="bx bxs-contact"></i>
                  <span data-key="Contactos">{{ $t("Contactos") }}</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                    to="/messages"
                    class="nav-link menu-link"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarMessages"
                    :class="{ 'active': router.name === 'messages' }"
                >
                  <i class="bx bxs-message-detail"></i>
                  <span data-key="Mensajes">{{ $t("Mensajes") }}</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                    to="/reports"
                    class="nav-link menu-link"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarReports"
                    :class="{ 'active': router.name === 'reports' }"
                >
                  <i class="bx bxs-report"></i>
                  <span data-key="Reportes">{{ $t("Reportes") }}</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                    to="/activity-logs"
                    class="nav-link menu-link"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarActivityLogs"
                    :class="{ 'active': router.name === 'activityLogs' }"
                >
                  <i class="bx bx-sort-up"></i>
                  <span data-key="Log de Actividades">{{ $t("Log de Actividades") }}</span>
                </router-link>
              </li>

            </ul>
          </b-container>
          <!-- Sidebar -->
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
      </div>

      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <b-container fluid>
            <slot />
          </b-container>
        </div>
        <Footer />
      </div>
      <RightBar/>
    </div>
  </div>
</template>
