<template>
    <footer class="footer">
        <b-container fluid>
            <b-row>
                <b-col col sm="6">
                    {{ new Date().getFullYear() }} © iTOCAPP.
                </b-col>
                <b-col col sm="6">
                    <div class="text-sm-end d-none d-sm-block">
                      Copyright © {{ new Date().getFullYear() }} Todos los derechos reservados Adix NT | Tienes problemas?
                      <a href="#" class="text-danger">
                        Contacta soporte
                      </a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>
