<script>
import {defineComponent} from "vue";
import {authComputed, authMethods} from "@/state/helpers";
import { useRouter } from 'vue-router';
import {getProfileName} from "@/helpers/helper";
import {getFirstLetters} from "@/helpers/words";

export default defineComponent({
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    getFirstLetters,
    ...authMethods,
    toggleHamburgerMenu() {
      const windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
          document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu") ?
            document.body.classList.remove("menu") :
            document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") === "sm" ?
              document.documentElement.setAttribute("data-sidebar-size", "") :
              document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") === "lg" ?
              document.documentElement.setAttribute("data-sidebar-size", "sm") :
              document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
        document.body.classList.contains("twocolumn-panel") ?
            document.body.classList.remove("twocolumn-panel") :
            document.body.classList.add("twocolumn-panel");
      }
    },

    // initFullScreen() {
    //   document.body.classList.toggle("fullscreen-enable");
    //   if (
    //       !document.fullscreenElement &&
    //       /* alternative standard method */
    //       !document.mozFullScreenElement &&
    //       !document.webkitFullscreenElement
    //   ) {
    //     // current working methods
    //     if (document.documentElement.requestFullscreen) {
    //       document.documentElement.requestFullscreen();
    //     } else if (document.documentElement.mozRequestFullScreen) {
    //       document.documentElement.mozRequestFullScreen();
    //     } else if (document.documentElement.webkitRequestFullscreen) {
    //       document.documentElement.webkitRequestFullscreen(
    //           Element.ALLOW_KEYBOARD_INPUT
    //       );
    //     }
    //   } else {
    //     if (document.cancelFullScreen) {
    //       document.cancelFullScreen();
    //     } else if (document.mozCancelFullScreen) {
    //       document.mozCancelFullScreen();
    //     } else if (document.webkitCancelFullScreen) {
    //       document.webkitCancelFullScreen();
    //     }
    //   }
    // },

    // toggleDarkMode() {
    //   if (document.documentElement.getAttribute("data-layout-mode") === "dark") {
    //     document.documentElement.setAttribute("data-layout-mode", "light");
    //   } else {
    //     document.documentElement.setAttribute("data-layout-mode", "dark");
    //   }
    // },

    logout() {
      this.setLogoutUser(null);
      localStorage.removeItem('accessToken');
      sessionStorage.removeItem('authUser');
      this.router.push({ name: 'login' });
    }
  },
  mounted() {
    document.addEventListener("scroll", function () {
      const pageTopbar = document.getElementById("page-topbar");

      if (pageTopbar) {
        document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
            "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
      }
    });

    if (document.getElementById("topnav-hamburger-icon")) {
      document
          .getElementById("topnav-hamburger-icon")
          .addEventListener("click", this.toggleHamburgerMenu);
    }

    if (document.getElementById("page-topbar")) {
      const pageTopbar = document.getElementById("page-topbar").offsetWidth;
      const layoutWidth = document.getElementById("layout-width").offsetWidth;
      const topbarUser = document.getElementById("topbar-user");
      const marginRight = pageTopbar - (layoutWidth + topbarUser.offsetWidth);

      topbarUser.style.marginRight = `${marginRight}px`;
    }
  },
  computed: {
    ...authComputed,
    auth() {
      return this.currentUser;
    },
    fullName() {
      if (this.currentUser !== null) {
        return this.currentUser.name + ' ' + this.currentUser.lastName;
      }

      return '';
    },
    profile() {
      if (this.currentUser !== null) {
        return getProfileName(this.currentUser.profile);
      }

      return '';
    }
  }
});
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width" id="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->

          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo/adix_nt_header_logo.png" alt="" height="53"/>
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo/adix_nt_header_logo.png" alt="" height="53"/>
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo/adix_nt_header_logo.png" alt="" height="53"/>
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo/adix_nt_header_logo.png" alt="" height="53"/>
              </span>
            </router-link>
          </div>

          <button type="button" class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            " id="topnav-hamburger-icon">
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

        </div>

        <div class="d-flex align-items-center">

          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="ri-settings-5-fill fs-22 text-red-custom"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
              <div class="
                  p-3
                  border-top-0 border-start-0 border-end-0 border-dashed border
                ">
                <b-row class="align-items-center">
                  <b-col>
                    <h6 class="m-0 fw-semibold fs-15">Opciones</h6>
                  </b-col>
                  <b-col cols="auto">
                    <router-link to="/group" class="btn btn-sm btn-soft-danger">
                      Ir a Grupos
                      <i class="ri-arrow-right-s-line align-middle"></i>
                    </router-link>

                  </b-col>
                </b-row>
              </div>

              <div class="p-2">
                <b-row class="g-0">
                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <i class="ri-list-settings-fill fs-24"></i>
                      <span>Mi cuenta</span>
                    </b-link>
                  </b-col>

                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <i class="ri-account-pin-box-fill fs-24"></i>
                      <span>Usuarios</span>
                    </b-link>
                  </b-col>

                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <i class="ri-calendar-todo-fill fs-24"></i>
                      <span>Días Feriados</span>
                    </b-link>
                  </b-col>
                </b-row>

                <b-row class="g-0">
                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <i class="ri-notification-off-fill fs-24"></i>
                      <span>Administrar Notificaciones</span>
                    </b-link>
                  </b-col>

                </b-row>
              </div>
            </div>
          </div>

          <div class="bg-gray-custom shape-custom"></div>

<!--          <div class="ms-1 header-item d-none d-sm-flex">-->
<!--            <b-button type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle"-->
<!--                      data-toggle="fullscreen" @click="initFullScreen">-->
<!--              <i class="bx bx-fullscreen fs-22"></i>-->
<!--            </b-button>-->
<!--          </div>-->

<!--          <div class="ms-1 header-item d-none d-sm-flex">-->
<!--            <b-button type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle light-dark-mode"-->
<!--                      @click="toggleDarkMode">-->
<!--              <i class="bx bx-moon fs-22"></i>-->
<!--            </b-button>-->
<!--          </div>-->

          <div class="dropdown ms-sm-0 header-item topbar-user" id="topbar-user">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
<!--                <img-->
<!--                    class="rounded-circle header-profile-user"-->
<!--                    src="@/assets/images/placeholders/placeholder.png"-->
<!--                    alt="Header Avatar"/>-->
                <div class="avatar-sm img-thumbnail rounded-circle flex-shrink-0 bg-green-dark-custom">

                  <div
                      class="avatar-title text-primary rounded-circle text-uppercase fs-24 bg-green-dark-custom text-green-dark-custom"
                  >
                    {{ getFirstLetters(fullName.replace(/\s/g, '')) }}
                  </div>

                </div>

                <span class="text-start ms-xl-2">
                  <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ fullName }}</span>
                  <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{ profile }}</span>
                </span>
              </span>
            </button>

            <div class="dropdown-menu dropdown-menu-end">
              <h6 class="dropdown-header text-black">{{ fullName }}!</h6>
              <router-link class="dropdown-item" to="/pages/profile">
                <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{ profile }}</span>
              </router-link>

              <div class="dropdown-divider"></div>

              <b-link
                  class="dropdown-item"
                  @click="logout"
              >
                <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Cerrar Sesión</span>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
#topbar-user {
  width: 320px !important;
}

.shape-custom {
  width: 60px;
  height: 70px;
  clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%);
}
</style>
